const avg = (list) => list.reduce((a, b) => a + b, 0) / list.length;

const numericDistance = (n1, n2, k = 1) => {
  const v1 = parseFloat(n1),
    v2 = parseFloat(n2);
  if (isNaN(v1) && isNaN(v2))
    return .1;
  if (isNaN(v1) || isNaN(v2))
    return 0;
  return 1 / (1 + Math.abs(v1 - v2) / k)
}

const arrayDistance = (a1, a2, k = 1) => {
  const v1 = !!a1 && !!a1.length && a1;
  const v2 = !!a2 && !!a2.length && a2;
  if (!v1 && !v2)
    return .1
  if (!v1 || !v2)
    return 0
  const only1 = a1.filter(e => !a2.includes(e)).length
  const only2 = a2.filter(e => !a1.includes(e)).length
  return 1 / (1 + avg([only1, only2]) / k)
}

const exactMatch = (list1, list2) => {
  const un = (e) => e === undefined || e === null
  const res = []
  for (let i = 0; i < Math.max(list1.length, list2.length); i++) {
    if (un(list1[i]) && un(list2[i])) {
      res.push(.1)
      continue
    }
    if (un(list1[i]) || un(list2[i])) {
      res.push(0)
      continue
    }
    res.push(list1[i] === list1[i] && 1 || 0)
  }
  return avg(res)
}

export default (g1, g2) => {
  if (!g1.content || !g2.content) return 0;
  if (g1.id === g2.id) return 1;

  const metrics = []
  if (g1.content.tags && g2.content.tags)
    metrics.push(Math.tanh(g1.content.tags.filter(t => g2.content.tags.includes(t)).length / 2))

  metrics.push(numericDistance(g1.content.playtime, g2.content.playtime, 8))
  metrics.push(numericDistance(g1.content.ageLimit, g2.content.ageLimit, 4))

  metrics.push(numericDistance(g1.content.playerCount && g1.content.playerCount.from,
    g2.content.playerCount && g2.content.playerCount.from, 2))
  metrics.push(numericDistance(g1.content.playerCount && g1.content.playerCount.to,
    g2.content.playerCount && g2.content.playerCount.to, 6))

  metrics.push(arrayDistance(g1.content.platforms, g2.content.platforms, 2))
  metrics.push(exactMatch(
    [g1.content.hunLocalized, g1.content.registeredUserNeeded, g1.content.groupNeeded, g1.content.meetNeeded],
    [g2.content.hunLocalized, g2.content.registeredUserNeeded, g2.content.groupNeeded, g2.content.meetNeeded]
  ))

  if (!metrics.length)
    return 0
  return avg(metrics)
}
